import React from "react";


function Contact() {
    return (
        <h1 className="page-title">Contact</h1>
    );
}

export default Contact;

